import service from '@/utils/Fetch'

export function loginUser() {
  return service({
    url: '/login/user',
    method: 'post',
    data: {},
  })
}
export function loginOut() {
  return service({
    url: '/login_out',
    method: 'post',
    data: {},
  })
}

// 获取
export function getPublicKey() {
  return service({
    url: '/get_publicKey',
    method: 'post',
    data: {},
  })
}
export function sendCode(data) {
  return service({
    url: '/register/send_code',
    method: 'post',
    data: data,
  })
}
export function forgetSendCode(data) {
  return service({
    url: '/forget/send_code',
    method: 'post',
    data: data,
  })
}

export function newAccount(data) {
  return service({
    url: '/register/new_account',
    method: 'post',
    data: data,
  })
}
export function resetPass(data) {
  return service({
    url: '/reset_pass',
    method: 'post',
    data: data,
  })
}
export function checkMobile(data) {
  return service({
    url: '/register/check_mobile',
    method: 'post',
    data: data,
  })
}
export function fcheckMobile(data) {
  return service({
    url: '/forget/check_mobile',
    method: 'post',
    data: data,
  })
}

export function loginAuth(data) {
  return service({
    url: '/login_auth',
    method: 'post',
    data: data,
  })
}
export function dataList(data) {
  return service({
    url: '/data/list',
    method: 'post',
    data: data,
  })
}
export function dataDetail(data) {
  return service({
    url: 'data/detail',
    method: 'post',
    data: data,
  })
}

export function keyList(data) {
  return service({
    url: 'key/list',
    method: 'post',
    data: data,
  })
}
export function taskList(data) {
  return service({
    url: 'task/list',
    method: 'post',
    data: data,
  })
}
export function taskDetail(data) {
  return service({
    url: 'task/detail',
    method: 'post',
    data: data,
  })
}
export function dataUse(data) {
  return service({
    url: 'data/use',
    method: 'post',
    data: data,
  })
}
