import { createStore } from 'vuex';

export default createStore({
  state: { IsMenu: false, User: null },
  mutations: {
    setIsMenu(state, val) {
      state.IsMenu = val;
    },
    setUser(state, val) {
      state.User = val;
    },
  },
  actions: {},
  modules: {},
});
