<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component
        :is="Component"
        :key="route.path"
        v-if="route.meta.keepAlive"
      />
    </keep-alive>
    <component :is="Component" :key="route.path" v-if="!route.meta.keepAlive" />
  </router-view>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { onMounted } from 'vue'
const route = useRoute()
// onMounted(() => {
//   window.addEventListener('scroll', handleScroll, true);
// });
// const handleScroll = (e) => {
//   let scrollTop = e.target.scrollTop;
//   console.log(scrollTop, '距离顶部的距离');
// };

onMounted(() => {
  document.body.style.setProperty('--el-color-primary', '#5388fe')
})
</script>
<style>
#app {
  height: 100%;
  /* font-family: Medium; */
  font-family: auto;
}
.el-table {
  --el-table-row-hover-bg-color: #ebf1ff;
}
</style>
