<template>
  <div class="header_bar">
    <div class="bar_body">
      <div class="bar_left">
        <span
          class="iconfont bar_title"
          :class="
            store.state.IsMenu ? 'icon-choutizhankai' : 'icon-choutishouqi'
          "
          @click="setMenu"
        ></span>
        <span @click="setMenu" class="bar_title">
          <i
            :class="store.state.IsMenu ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        /></span>
        <!-- <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{ route.meta.title }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="route.meta.title2">{{
            route.meta.title2
          }}</el-breadcrumb-item>
        </el-breadcrumb> -->
      </div>
      <div class="bar_right" v-if="!userInfo">
        <div class="bar_avatar">
          <!-- <el-avatar
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar> -->
          <img src="../assets/img/noportrait.png" alt="" />
          <span class="primary_btn" @click="goLogin">请登录</span>
        </div>
      </div>
      <div class="bar_right" v-else>
        <el-dropdown teleported>
          <div class="bar_avatar">
            <img src="../assets/img/portrait.png" alt="" />
            <span class="userinfo">{{ userInfo }}</span>
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="exit">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <div class="service_title" v-if="route.name === 'dataServices'">
      <div class="borderbody">
        <div class="t_left">
          <img :src="imgData" alt />
          {{ imgName }}
        </div>
        <div class="t_right">
          <el-button
            type="primary"
            color="#5388fe"
            class="btn_color"
            @click="setBom"
            >立即使用</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { loginOut, loginUser } from '@/apis/api';
import $ from 'jquery';
import { ref, onMounted, nextTick, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
//判断数据信息title
const route = useRoute();
//导航收放
const store = useStore();
const setMenu = () => {
  store.commit('setIsMenu', !store.state.IsMenu);
};
const titleList = reactive({
  list: [],
});
watch(
  () => route.query,
  (cur, old) => {
    if ($('#scrollBox').length > 0 && $('#scrollBox')[0].scrollTop) {
      $('#scrollBox')[0].scrollTop = 0;
    }
    getDataDetail();
  }
);
//滑动
let timeTop = ref(null);
const setBom = () => {
  nextTick(() => {
    //要滑动的高度= 当前dom的滑动高度 - 当前窗口可视区域高度
    timeTop = setInterval(() => {
      if (
        $('#scrollBox')[0].scrollHeight -
          $('#scrollBox').height() -
          $('#scrollBox')[0].scrollTop <=
        2
      ) {
        clearInterval(timeTop);
        timeTop = null;
      } else {
        $('#scrollBox').scrollTop(($('#scrollBox')[0].scrollTop += 100));
      }
    }, 20);
  });
};
const getUser = async () => {
  const res = await loginUser();
  if (!res.status) return;
  store.commit('setUser', res.data.data);
};
const userInfo = ref(null);

const router = useRouter();
const exit = async () => {
  await loginOut();
  userInfo.value = null;
  sessionStorage.removeItem('userInfo');
  store.commit('setUser', null);
  const noGoLogin = ['index', 'dataServices'];
  if (noGoLogin.indexOf(route.name) < 0) {
    router.push({
      path: '/',
    });
  }
  console.log(route, 'route');
};
const goLogin = () => {
  router.push({
    path: '/login',
    query: {
      redirect: route.fullPath,
    },
  });
};
const imgData = ref('');
const imgName = ref('');
const getDataDetail = () => {
  setTimeout(() => {
    if (route.name !== 'dataServices') return;

    const list = sessionStorage.getItem('menuList');
    titleList.list = JSON.parse(list);
    console.log(titleList.list, 'list');
    if (titleList.list && titleList.list.length > 0) {
      const data = titleList.list.find(
        (item) => item.serviceId === Number(route.query.id)
      );
      if (!data) return;
      imgData.value = data.datasetImg;
      imgName.value = data.dataName;
    }
  }, 200);
};
onMounted(() => {
  getDataDetail();
  userInfo.value = sessionStorage.getItem('userInfo');
  store.commit('setUser', userInfo.value);
  getUser();
});
</script>

<style scoped lang="scss">
.header_bar {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
}
.bar_body {
  display: flex;
  height: 50px;
  border-bottom: 1px solid #d5d5d5;
  .bar_left {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .bar_title {
    cursor: pointer;
    font-size: 20px;
    margin: 0 8px;
  }
}
.el-breadcrumb {
  font-size: 14px;
}

.bar_right {
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: auto;
}

.bar_avatar {
  display: flex;
  align-items: center;
  margin-right: 12px;
  font-size: 14px;
  cursor: pointer;
  color: #242424;
  .userinfo {
    color: #242424;
  }
  img {
    height: 24px;
    margin-right: 8px;
  }
  .primary_btn {
    font-size: 14px;
    color: #5388fe;
  }
  .primary_btn:focus,
  .primary_btn:hover {
    color: #87acfe;
  }
  .primary_btn:active {
    color: #4671cf;
  }
}

.el-avatar {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.service_title {
  height: 86px;
  width: 100%;
  background-color: #ffffff;
  // box-shadow: 0px 3px 6px #00000029;
  display: flex;
  // align-items: center;
  // position: fixed;
  .borderbody {
    display: flex;
    align-items: center;
    height: 80px;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    .t_right {
      margin-left: auto;
      margin-right: 20px;
      .el-button {
        color: #fff;
      }
    }
  }
  .t_left {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    padding-left: 26px;
    font-weight: 500;
    width: -webkit-fill-available;
    img {
      height: 38px;
      margin-right: 16px;
    }
  }
}
</style>
<style>
.el-dropdown-menu {
  width: 140px;
}
.el-dropdown-menu__item {
  justify-content: center;
}
</style>
