<template>
  <div class="menu_item">
    <el-menu
      :collapse="store.state.IsMenu"
      :default-openeds="['1111']"
      background-color="#080B19"
      text-color="#FFFFFFBE"
      active-text-color="#fff"
      @open="handleOpen"
      @close="handleClose"
      class="el-menu-vertical-demo"
    >
      <el-menu-item index="4567" class="menu_title" @click="goHome">
        <el-icon>
          <img class="logo" src="../assets/img/tianwen.png" alt
        /></el-icon>
        <template #title v-if="!store.state.IsMenu">数据服务平台</template>
      </el-menu-item>
      <el-sub-menu index="1111">
        <template #title>
          <el-icon
            ><span class="iconfont icon-chanyedashujufuwupingtai1"></span
          ></el-icon>
          <span>数据服务</span>
        </template>
        <el-menu-item-group>
          <div v-if="!store.state.IsMenu">
            <el-tooltip
              class="item"
              effect="dark"
              v-for="item in servicesData.list"
              :key="item.serviceId"
              :content="item.dataName"
              placement="right"
              :offset="10"
              :disabled="item.dataName.length < 12"
            >
              <el-menu-item
                :index="item.dataName"
                @click="setMenu(item)"
                :style="{
                  'background-color':
                    route.name === 'dataServices' &&
                    route.query.id === item.serviceId + ''
                      ? '#536dfe'
                      : '',
                }"
              >
                <span
                  class="item-menu-sp"
                  :style="{
                    color:
                      route.name === 'dataServices' &&
                      route.query.id === item.serviceId + ''
                        ? '#fff'
                        : '#FFFFFFBE',
                  }"
                  >{{ item.dataName }}</span
                >
              </el-menu-item>
            </el-tooltip>
          </div>

          <div v-else>
            <el-menu-item
              v-for="item in servicesData.list"
              :key="item.serviceId"
              :index="item.dataName"
              @click="setMenu(item)"
            >
              <span
                class="item-menu-sp"
                :style="{
                  color:
                    route.name === 'dataServices' &&
                    route.query.id === item.serviceId + ''
                      ? '#fff'
                      : '#FFFFFFBE',
                }"
                >{{ item.dataName }}</span
              >
            </el-menu-item>
          </div>
        </el-menu-item-group>
      </el-sub-menu>
      <el-tooltip
        class="item"
        effect="dark"
        content="我的任务"
        placement="right"
        :offset="10"
        v-if="store.state.IsMenu && store.state.User"
      >
        <el-menu-item
          index="2222"
          @click="setMenuTap('mission')"
          :style="{
            color:
              route.name === 'mission' || route.name === 'missionDetails'
                ? '#fff'
                : '#FFFFFFBE',
            'background-color':
              route.name === 'mission' || route.name === 'missionDetails'
                ? '#536dfe'
                : '',
          }"
        >
          <el-icon><span class="iconfont icon-woderenwu"></span></el-icon>
        </el-menu-item>
      </el-tooltip>

      <el-menu-item
        index="2222"
        @click="setMenuTap('mission')"
        v-if="!store.state.IsMenu && store.state.User"
        :style="{
          color:
            route.name === 'mission' || route.name === 'missionDetails'
              ? '#fff'
              : '#FFFFFFBE',
          'background-color':
            route.name === 'mission' || route.name === 'missionDetails'
              ? '#536dfe'
              : '',
        }"
      >
        <el-icon><span class="iconfont icon-woderenwu"></span></el-icon>
        <span>我的任务</span>
      </el-menu-item>
      <el-tooltip
        class="item"
        effect="dark"
        content="密钥管理"
        placement="right"
        :offset="10"
        v-if="store.state.IsMenu && store.state.User"
      >
        <el-menu-item
          index="3333"
          @click="setMenuTap('keyManagement')"
          :style="{
            color: route.name === 'keyManagement' ? '#fff' : '#FFFFFFBE',
            'background-color': route.name === 'keyManagement' ? '#536dfe' : '',
          }"
        >
          <el-icon> <span class="iconfont icon-key"></span></el-icon>
          <span>密钥管理</span>
        </el-menu-item>
      </el-tooltip>
      <el-menu-item
        index="3333"
        @click="setMenuTap('keyManagement')"
        v-if="!store.state.IsMenu && store.state.User"
        :style="{
          color: route.name === 'keyManagement' ? '#fff' : '#FFFFFFBE',
          'background-color': route.name === 'keyManagement' ? '#536dfe' : '',
        }"
      >
        <el-icon> <span class="iconfont icon-key"></span></el-icon>
        <span>密钥管理</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { dataList } from '@/apis/api'
import { ref, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const store = useStore()
let servicesData = reactive({
  list: [],
  openeds: [],
})
const handleOpen = (key, keyPath) => {
  console.log(key, keyPath)
}
const handleClose = (key, keyPath) => {
  console.log(key, keyPath)
}
const setMenu = (val) => {
  // console.log(val, 'val');
  router.push({
    path: '/dataServices',
    query: {
      id: val.serviceId,
    },
  })
}
const setMenuTap = (val) => {
  router.push({
    path: `/${val}`,
  })
}
const getDataList = async () => {
  const res = await dataList({})
  servicesData.list = res.data.dataList
  sessionStorage.setItem('menuList', JSON.stringify(res.data.dataList))
}
const goHome = () => {
  router.push({
    path: '/',
  })
}
onMounted(() => {
  const list = sessionStorage.getItem('menuList')
  if (list && JSON.parse(list).length > 0) {
    servicesData.list = JSON.parse(list)
  } else {
    getDataList()
  }
  // getDataList()
})
</script>

<style scoped lang="scss">
.menu_title {
  i {
    margin-right: 24px;
  }
  font-size: 16px;
  color: #fff;
  img {
    height: 24px;
    // margin-left: 8px;
    cursor: pointer;
  }
}
.logo_menu {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    height: 24px;
    margin-left: 8px;
    cursor: pointer;
  }
  span {
    font-size: 16px;
    color: #ffffff;
    margin-left: 8px;
  }
}
.iconfont {
  font-size: 16px;
  // margin-right: 8px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
  height: 100%;
}
.menu_item {
  height: 100%;
  max-width: 220px;
}

.el-menu {
  height: 100%;
  border-right: 0px !important;
  background-color: transparent !important;
}

.el-menu-item:hover {
  background-color: #536dfe !important;
}
.item-menu-sp {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu_title:hover {
  background-color: transparent !important;
}
.el-menu-item:active {
  background-color: #536dfe !important;
}
.menu_title:active {
  background-color: #011529 !important;
}
:deep(.el-menu-item-group__title) {
  padding: 0 !important;
}
</style>
<style>
.el-popper[data-popper-placement^='right'] > .el-popper {
  left: -20px;
}
</style>
