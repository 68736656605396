import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        name: 'index',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Index.vue'),
        meta: {
          title: '首页',
        },
      },
      {
        path: '/dataServices',
        name: 'dataServices',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/dataServices/Index.vue'
          ),
        meta: {
          title: '数据服务',
        },
      },
      {
        path: '/mission',
        name: 'mission',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/mission/Index.vue'),
        meta: {
          title: '任务列表',
          tabs: 1,
          auth: true,
          keepAlive: true,
        },
      },
      {
        path: '/missionDetails',
        name: 'missionDetails',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/mission/Details.vue'
          ),
        meta: {
          title: '任务列表',
          title2: '任务详情',
          auth: true,
        },
      },
      {
        path: '/keyManagement',
        name: 'keyManagement',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/keyManagement/Index.vue'
          ),
        meta: {
          title: '密钥管理',
          auth: true,
        },
      },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/error/404.vue'),
        meta: {
          title: '404',
        },
      },
      {
        path: '/403',
        name: '403',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/error/403.vue'),
        meta: {
          title: '403',
        },
      },
      {
        path: '/500',
        name: '500',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/error/500.vue'),
        meta: {
          title: '500',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/termsService',
    name: 'termsService',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/agreement/TermsService.vue'
      ),
    meta: {
      title: '服务条款',
      auth: false,
    },
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/agreement/PrivacyPolicy.vue'
      ),
    meta: {
      title: '隐私声明',
      auth: false,
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/error/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes, // `routes: routes` 的缩写
});

// 路由守卫
router.beforeEach((to, from, next) => {
  // if (to.name !== from.name) {
  //   document.body.scrollTop = 0;
  //   // firefox
  //   document.documentElement.scrollTop = 0;
  //   // safari
  //   window.pageYOffset = 0;
  // }
  // to要跳转到的路径
  // from从哪个路径来
  // next往下执行的回调
  // 判断该页面是否需要登录、
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  const user = sessionStorage.getItem('userInfo');
  console.log(user, 'user');
  if (to.meta.auth) {
    if (user) {
      next();
    } else {
      next({
        path: '/login',
        // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    if (to.path === '/login') {
      if (user) {
        next({
          path: '/',
        });
      } else {
        next();
      }
    } else {
      // 如果不需要登录，则直接跳转到对应页面
      next();
    }
  }
});

export default router;
