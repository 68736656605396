<template>
  <div class="home">
    <el-container>
      <el-aside>
        <menu-item />
        <div class="fotboy" v-show="isShow">
          <span>Powered by </span>
          <a
            href="https://dianshudata.com/"
            class="bluetext"
            target="view_window"
            >典枢数据合作平台</a
          >
        </div>
      </el-aside>
      <el-container>
        <el-header>
          <header-bar />
        </el-header>
        <el-main id="scrollBox">
          <router-view />
          <el-footer>
            Copyright © YeeZTech 北京熠智科技有限公司 版权所有
            <img src="../assets/img/guo.png" alt="" />
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802038645"
              target="_blank"
              class="onea"
            >
              京公网安备11010802038645号
            </a>
            <span> | </span>
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >京ICP备20022436号-3
            </a>
          </el-footer>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import MenuItem from '../components/MenuItem.vue'
import HeaderBar from '../components/HeaderBar.vue'
import { useStore } from 'vuex'
import { ref, watch, onMounted } from 'vue'
const store = useStore()
const isShow = ref(false)
watch(
  () => store.state.IsMenu,
  (cur, old) => {
    console.log(cur, 'home')
    if (!cur) {
      setTimeout(() => {
        isShow.value = !cur
      }, 300)
    } else {
      isShow.value = !cur
    }
  }
)
onMounted(() => {
  console.log(store.state.IsMenu)
  isShow.value = !store.state.IsMenu
})
</script>
<style scoped lang="scss">
.home {
  height: 100%;
  width: 100%;
  display: flex;
  min-width: 1024px;
}

.logo_menu {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    height: 24px;
    margin-left: 8px;
    cursor: pointer;
  }
  span {
    font-size: 16px;
    color: #ffffff;
    margin-left: 8px;
  }
}

.el-aside {
  display: flex;
  flex-direction: column;
  background-color: #011529;
  width: initial !important;
  // max-width: 210px;
}

.el-header {
  padding: 0;
  // height: 130px !important;

  height: initial !important;
}

.el-main {
  background-color: #f5f6fa;
  padding: 0;
  // padding: 16px;
  display: flex;
  flex-direction: column;
  :deep(.el-dialog__body) {
    padding-top: 0px !important;
  }
}

.el-footer {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: #8d8d8d;
  font-size: 14px;
  img {
    margin-left: 4px;
  }
  a {
    color: #8d8d8d;
    // color: #5388fe;
    text-decoration: auto;
    cursor: pointer;
  }
  span {
    margin: 0 4px;
  }
  .onea {
    margin-left: 4px;
  }
}
.fotboy {
  height: 60px;
  font-size: 14px;
  color: #ffffffbe;
  text-align: center;
  // position: fixed;
  bottom: 14px;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 4px;
  }
}
.bluetext {
  color: #ffffffbe;
  // color: #5388fe;
  text-decoration: auto;
  cursor: pointer;
}
</style>
