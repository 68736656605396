import axios from 'axios'
import router from '@/router/index'
// 创建一个 axios 实例
const service = axios.create({
  baseURL: '/api', // 所有的请求地址前缀部分
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  // headers: {
  // 	// 设置后端需要的传参类型
  // 	'Content-Type': 'application/json',
  // 	'token': 'your token',
  // 	'X-Requested-With': 'XMLHttpRequest',
  // },
})

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    console.log(error)
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    if (response.data.resultCode === 100) {
      return {
        status: true,
        data: response.data,
      }
    }
    if (response.data.resultCode === 400) {
      sessionStorage.removeItem('userInfo')
      router.push({ path: '/login' })
      return {
        status: false,
        data: response.data,
      }
    }
    if (response.data.resultCode === 404) {
      router.push({ path: '/404' })
      return {
        status: false,
        data: response.data,
      }
    }

    if (response.data.resultCode === 500) {
      router.push({ path: '/500' })
      return {
        status: false,
        data: response.data,
      }
    }
    if (response.data.resultCode === 403) {
      router.push({ path: '/403' })
      return {
        status: false,
        data: response.data,
      }
    }
    if (response.data.resultCode !== 100) {
      return {
        status: false,
        msg: response.data.resultDesc,
      }
    }
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
