import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/main.css'
import './assets/publicClass.scss'
import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont.js'
import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
import githubTheme from '@kangc/v-md-editor/lib/theme/github'
import '@kangc/v-md-editor/lib/theme/style/github.css'
import hljs from 'highlight.js'
import 'animate.css/animate.min.css'
import preventReClick from './utils/preventReClick'

VMdPreview.use(githubTheme, {
  Hljs: hljs,
})
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router).use(store).use(ElementPlus).use(VMdPreview).use(preventReClick)
app.mount('#app')
// createApp(App).use(router).use(store).mount('#app')
